<template>
    <div>
        <div v-if="authUserPermission['stock-calc-fast-by-manual']">
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="h2 text-white d-inline-block mb-0"></h6>
                    </div>      
                </div>
            </base-header>
            <div class="container-fluid mt--6">
                <div class="card mb-4">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-7">
                                <h3 class="mb-0">Stock Calculation by Manual Input</h3>
                            </div>
                            <!-- <div class="col text-right">
                                 <base-button size="sm" type="default" @click="refreshData" :disabled="loadingRefresh">
                                        <span v-if="loadingRefresh"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                                        <span v-else>Take Out</span>
                                </base-button>
                            </div> -->
                        </div>
                    </div>
                    <div class="card-body">
                        <!-- <label class="form-control-label"><b>Request Demand</b></label> -->
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped">
                                <tbody>
                                    <tr>
                                        <td class="align-middle">Request Demand
                                            <el-tooltip content="Demand request dalam 12 bulan untuk simulasi grafik" placement="top">
                                                <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                            </el-tooltip> 
                                        </td>
                                        <td>
                                            <el-tooltip content="Bulan Ke-1" placement="top">
                                                <input class="form-control" placeholder="0" v-model="demandRequest[0]" :disabled="loadingCalculate" type="number">
                                            </el-tooltip> 
                                        </td>
                                        <td>
                                            <el-tooltip content="Bulan Ke-2" placement="top">
                                                <input class="form-control" placeholder="0" v-model="demandRequest[1]" :disabled="loadingCalculate" type="number">
                                            </el-tooltip>
                                        </td>
                                        <td>
                                            <el-tooltip content="Bulan Ke-3" placement="top">
                                                <input class="form-control" placeholder="0" v-model="demandRequest[2]" :disabled="loadingCalculate" type="number">
                                            </el-tooltip>
                                        </td>
                                        <td>
                                            <el-tooltip content="Bulan Ke-4" placement="top">
                                                <input class="form-control" placeholder="0" v-model="demandRequest[3]" :disabled="loadingCalculate" type="number">
                                            </el-tooltip>
                                        </td>
                                        <td>
                                            <el-tooltip content="Bulan Ke-5" placement="top">
                                                <input class="form-control" placeholder="0" v-model="demandRequest[4]" :disabled="loadingCalculate" type="number">
                                            </el-tooltip>
                                        </td>
                                        <td>
                                            <el-tooltip content="Bulan Ke-6" placement="top">
                                                <input class="form-control" placeholder="0" v-model="demandRequest[5]" :disabled="loadingCalculate" type="number">
                                            </el-tooltip>
                                        </td>
                                        <td>
                                            <el-tooltip content="Bulan Ke-7" placement="top">
                                                <input class="form-control" placeholder="0" v-model="demandRequest[6]" :disabled="loadingCalculate" type="number">
                                            </el-tooltip>
                                        </td>
                                        <td>
                                            <el-tooltip content="Bulan Ke-8" placement="top">
                                                <input class="form-control" placeholder="0" v-model="demandRequest[7]" :disabled="loadingCalculate" type="number">
                                            </el-tooltip>
                                        </td>
                                        <td>
                                            <el-tooltip content="Bulan Ke-9" placement="top">
                                                <input class="form-control" placeholder="0" v-model="demandRequest[8]" :disabled="loadingCalculate" type="number">
                                            </el-tooltip>
                                        </td>
                                        <td>
                                            <el-tooltip content="Bulan Ke-10" placement="top">
                                                <input class="form-control" placeholder="0" v-model="demandRequest[9]" :disabled="loadingCalculate" type="number">
                                            </el-tooltip>
                                        </td>
                                        <td>
                                            <el-tooltip content="Bulan Ke-11" placement="top">
                                                <input class="form-control" placeholder="0" v-model="demandRequest[10]" :disabled="loadingCalculate"  type="number">
                                            </el-tooltip>
                                        </td>
                                        <td>
                                            <el-tooltip content="Bulan Ke-12" placement="top">
                                                <input class="form-control" placeholder="0" v-model="demandRequest[11]" :disabled="loadingCalculate"  type="number">
                                            </el-tooltip>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="align-middle">Lead Time
                                            <el-tooltip placement="top">
                                                <template #content>Jika leadtime bervariasi contohnya 3 bulan, 4.5 bulan <br>maka dapat tambahkan kolom jika leadtime fixed atau konstan <br>maka cukup gunakan satu kolom leadtime</template>
                                                <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                            </el-tooltip>
                                        </td>
                                        <template id="">
                                            <td v-for="(lead, key) in leadTimes" :key="key+'lead'"><input class="form-control" placeholder="0" v-model="leadTimes[key]"  :disabled="loadingCalculate" type="number"></td>
                                        </template>
                                        <td>
                                            <button class="btn btn-primary mr-1" @click="addLeadTime()">
                                                <el-tooltip placement="top">
                                                    <i class="fa fa-plus"></i>

                                                    <template #content>Jika leadtime bervariasi contohnya 3 bulan, 4.5 bulan <br>maka dapat tambahkan kolom jika leadtime fixed atau konstan <br>maka cukup gunakan satu kolom leadtime</template>
                                                </el-tooltip>
                                            </button>
                                            <button class="btn btn-danger" @click="deleteLeadTime"><i class="fa fa-minus"></i></button>
                                        </td>
                                        
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="row mt-3">
                            <div class="col-4">
                                <label class="form-control-label"><b>Initial Inventory</b>
                                    <el-tooltip content="Ketersediaan stok di gudang saat ini, untuk dimulasi grafik" placement="top">
                                        <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                    </el-tooltip> 
                                </label>
                                <input class="form-control" placeholder="Initial Inventory" v-model="initialInventory" :disabled="loadingCalculate"  type="number">

                            </div>

                            <div class="col-4">
                                <label class="form-control-label"><b>Unit Price</b>
                                       <el-tooltip content="Harga satuan Spare Part" placement="top">
                                            <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                        </el-tooltip> 
                                </label>
                                <input class="form-control" placeholder="Unit Price" v-model="unitPrice" type="text" :disabled="loadingCalculate" @keyup="formatPrice($event, 'unitPrice')">

                            </div>

                            <div class="col-4">
                                <label class="form-control-label"><b>Metode ROQ</b>
                                    <el-tooltip placement="top">
                                        <div slot="content">1. ROQ dengan parameter biaya: <br> Perhitungannya mempertimbangkan <br> biaya penyimpanan (holding cost), <br> biaya pemesanan (ordering cost) <br/> <br>2. ROQ tanpa parameter biaya: <br> perhitungannya tidak mempertimbangkan <br> biaya penyimpanan (holding cost), <br> biaya pemesanan (ordering cost) </div>
                                        <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                    </el-tooltip> 
                                </label><br>
                                <el-select v-model="metodeEoq" style="width:100%" placeholder="Choose" :disabled="loadingCalculate || loadingRefresh">
                                    <el-option label="ROQ Dengan Parameter Biaya" value="true"></el-option>
                                    <el-option label="ROQ Tanpa Parameter Biaya" value="false"></el-option>
                                </el-select>
                            </div>

                            <div class="col-4">
                                <label class="form-control-label"><b>Order Cost</b>
                                    <el-tooltip placement="top">
                                        <template #content>Biaya pemesanan setiap kali pesan, <br>biaya pemesanan ini dapat mencakup biaya pengiriman, <br>biaya transportasi tak terduga, biaya inspeksi, dan biaya lain <br> yang diperlukan untuk memperoleh produk persediaan.</template>
                                        <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                    </el-tooltip>
                                </label>
                                <input class="form-control" placeholder="Order Cost" v-model="orderCost" type="text" :disabled="loadingCalculate || disableOrderCost" @keyup="formatPrice($event, 'orderCost')">
                            </div>

                            <div class="col-4">
                                <label class="form-control-label"><b>Annual Carrying Cost (%)</b>
                                    <el-tooltip content="Biaya pengimpanan persediaan/Spare Part, persentase dari unit price" placement="top">
                                        <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                    </el-tooltip> 
                                </label>
                                <input class="form-control" placeholder="Annual Carrying Cost (%)" v-model="annualCarryingCost" :disabled="loadingCalculate || disableAnnual"  type="number">
                            </div>
                            
                            <div class="col-4">
                                <label class="form-control-label"><b>Service Level (%)</b>
                                    <el-tooltip placement="top">
                                        <template #content>Kemampuan memberikan pelayanan kepada user <br> pemeliharaan yang diukur berdasarkan rasio <br> antara tingkat ketersediaan (availability) Material Stock <br> dengan frekuensi permintaan.</template>
                                        <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                    </el-tooltip> 
                                </label>
                                <div class="input-group" v-for="(service, key) in serviceLevel" :key="key">
                                    <input class="form-control mb-4" placeholder="Service Level (%)" type="number" v-model="serviceLevel[key]" :disabled="loadingCalculate">
                                    <div class="input-group-btn">
                                        <button class="btn btn-primary mr-1" @click="addServiceLevel(key)" :disabled="loadingCalculate" v-if="key == 0"><i class="fa fa-plus"></i></button>
                                        <button class="btn btn-danger" @click="removeServiceLevel(key)" :disabled="loadingCalculate || key == 0"><i class="fa fa-minus"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="text-center mt-5">
                            <input type="button" class="btn btn-danger" value="Run Calculation" @click="calculate" :disabled="loadingCalculate || disableCalculate || loadingDownload">
                            <base-button  type="default" @click="resetParameter"  :disabled="loadingCalculate || loadingDownload">Reset</base-button>
                        </div>

                        <div v-if=" calculationData && calculationData.length > 0 && !loadingCalculate"> 
                            <div class="table-responsive mt-5" v-if="calculationData.length > 0">
                                <div  v-if="!loadingCalculate">
                                <table class="table table-bordered table-striped">
                                    <thead class="bg-secondary">
                                        <tr>
                                            <!-- <th style="font-size:9pt">No</th> -->
                                            <th style="font-size:9pt">Service Level
                                                <el-tooltip content="Kemampuan memberikan pelayanan kepada user pemeliharaan yang diukur berdasarkan rasio antara tingkat ketersediaan (availability) Material Stock dengan frekuensi permintaan" placement="top">
                                                    <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                                </el-tooltip> 
                                            </th>

                                            <th style="font-size:9pt">Safety Stock
                                                <el-tooltip content="Persediaan pengaman (buffer stock), yang berguna untuk menjaga kemungkinan terjadinya Stock Out yang diakibatkan karena penggunaan material yang melebihi perkiraan semula atau dikarenakan Lead Time pengiriman material yang lebih lama dari yang diperkirakan" placement="top">
                                                    <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                                </el-tooltip> 
                                            </th>
                                            <th style="font-size:9pt">ROP
                                                <el-tooltip content="Re-Order Point sebagai batas posisi jumlah stock di mana pembelian kembali material harus dilakukan untuk mengisi kembali stock gudang" placement="top">
                                                    <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                                </el-tooltip> 
                                            </th>
                                            <th style="font-size:9pt">Min
                                                <el-tooltip content="Jumlah minimum stock yang harus selalu ada di gudang" placement="top">
                                                    <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                                </el-tooltip> 
                                            </th>
                                            <th style="font-size:9pt">Max
                                                <el-tooltip content="Jumlah maksimum stock yang harus selalu ada di gudang" placement="top">
                                                    <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                                </el-tooltip> 
                                            </th>
                                            <th style="font-size:9pt">EOQ
                                                <el-tooltip content="Reorder Quantity (ROQ) adalah rekomendasi jumlah pesanan dalam sekali pesan" placement="top">
                                                    <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                                </el-tooltip> 
                                            </th>
                                            <th style="font-size:9pt">Total Biaya
                                                <el-tooltip content="Total biaya yang dikeluarkan dari jumlah pesanan (ROQ) dikali harga satuan unit" placement="top">
                                                    <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                                </el-tooltip> 
                                            </th>
                                            <th style="font-size:9pt">Grafik
                                                <el-tooltip content="Simulasi kondisi stok gudang dari nilai-nilai perhitungan stok selama 12 bulan" placement="top">
                                                    <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                                </el-tooltip> 
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody  v-if="Object.keys(calculations).length > 0">
                                        <tr v-for="(calculate, key) in calculationData" :key="key">
                                            <!-- <td style="font-size:11pt">{{ key+1 }}</td> -->
                                            <td style="font-size:11pt">{{ calculate.service_level }}</td>
                                            <!-- <td style="font-size:11pt">{{ calculate.metode }}</td> -->
                                            <td style="font-size:11pt">{{ calculate.safety_stock }}</td>
                                            <td style="font-size:11pt">{{ calculate.reorder_point }}</td>
                                            <td style="font-size:11pt">{{ calculate.minimum_stock }}</td>
                                            <td style="font-size:11pt">{{ calculate.maximum_stock }}</td>
                                            <td style="font-size:11pt">{{ calculate.roq }}</td>
                                            <td style="font-size:11pt">{{ formatNumber(calculate.total_biaya)}}</td>
                                            <td style="font-size:11pt">
                                                <button type="button" class="btn btn-sm btn-primary" @click="displayChart(key)">Detail</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <base-button  type="default" @click="downloadFile"  :disabled="loadingDownload" class="float-right mt-3" size="sm">
                                    <span v-if="loadingDownload"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                                    <span v-else>Download</span>
                                    <el-tooltip content="Download tabel hasil perhitungan" placement="top">
                                        <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                    </el-tooltip>
                                </base-button>
                                </div>
                                <skeleton-loading v-else/>
                            </div>

                            <div class="mt-5 text-center" v-if="calculationData.length > 0">
                                <h3><b>Summary</b></h3>
                                <div class="py-3" style="border: 1px solid #858585">
                                    <!-- <span>Berdasarkan pada hasil perhitungan di atas dapat disimpulkan bahwa stok aman dalam 12 bulan ke depan, <br>jika dengan Service Level <b>{{ summary.Service_Level }}%</b>. Maka disarankan nilai :</span><br>
                                    <span><b>Safety Stock:</b> {{ !!summary ? summary.Safety_Stock.toFixed()  : ""}}, <b>Reorder Point:</b> {{ !!summary ? summary.Reorder_Point  : ""}}, <b>Reorder Quantity(ROQ):</b> {{ !!summary ? summary.ROQ  : ""}}, <b>Minimum:</b> {{ !!summary ? summary.Minimum_Stock  : ""}}, <b>Maximum:</b> {{ !!summary ? summary.Maximum_Stock  : ""}}</span> -->
                                    <span>Berdasarkan pada hasil perhitungan di atas dapat disimpulkan bahwa stok aman dalam 12 bulan ke depan, disarankan nilai :</span><br>
                                    <span><b>Safety Stock:</b> {{ !!summary ? summary.safety_stock : ""}}, <b>Reorder Point:</b> {{ !!summary ? summary.reorder_point  : ""}}, <b>Economic Order Quantity(EOQ):</b> {{ !!summary ? summary.roq  : ""}}, <b>Minimum:</b> {{ !!summary ? summary.minimum_stock  : ""}}, <b>Maximum:</b> {{ !!summary ? summary.maximum_stock  : ""}}</span>
                                </div>
                            </div>
                        </div>

                        <skeleton-loading v-else-if="loadingCalculate"></skeleton-loading>

                        <empty v-else></empty>

                        <!-- MODAL SMARTCAT ATTACHMENT -->
                        <modal :show.sync="modalDetail" size="lg">
                            <template slot="header">
                                <h5 class="modal-title">Detail & Grafik Hasil Perhitungan</h5>
                            </template>
                            <template>
                                <div id="canvasWrapper">
                                    <canvas id="myChart"></canvas>
                                </div>
                                <div class="table-responsive mt-5">
                                    <table class="table table-bordered customTableKecil">
                                        <tbody>
                                            <tr>
                                                <th>Month</th>
                                                <td>1</td>
                                                <td>2</td>
                                                <td>3</td>
                                                <td>4</td>
                                                <td>5</td>
                                                <td>6</td>
                                                <td>7</td>
                                                <td>8</td>
                                                <td>9</td>
                                                <td>10</td>
                                                <td>11</td>
                                                <td>12</td>
                                            </tr>
                                            <tr>
                                                <th>Availibility Inventory
                                                    <el-tooltip content="Stok Spare Part yang ada digudang" placement="top">
                                                        <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                                    </el-tooltip> 
                                                </th>
                                                <td v-for="(available, keyAvail) in availableInvetory" :key="keyAvail">{{available}}</td>
                                            </tr>
                                            <tr>
                                                <th>Order Entry
                                                    <el-tooltip content="Kuantiti Spare Part yang diterima" placement="top">
                                                        <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                                    </el-tooltip> 
                                                </th>
                                                <td v-for="(entry, keyEntry) in orderEntry" :class="{'text-primary fw-bold': (Number(entry) > 0)}" :key="keyEntry">{{entry}}</td>
                                            </tr>
                                            <tr>
                                                <th>Order Issue
                                                    <el-tooltip content="Kuantiti Spare Part yang dipesan" placement="top">
                                                        <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                                    </el-tooltip> 
                                                </th>
                                                 <td v-for="(issued, keyIssued) in orderIssued" :class="{'text-danger fw-bold': (Number(issued) > 0)}" :key="keyIssued">{{issued}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </template>
                        </modal>
                    </div>
                </div>
            </div>

        </div>
        <noaccess v-else/>
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import axios from 'axios'
    import Chart from 'chart.js/dist/chart.js'
    import SkeletonLoading from '../../components/SkeletonLoading.vue';
    import configUrl from '@/configs/config'
    import Empty from '../../components/Empty.vue';
    import * as XLSX from 'xlsx';

    export default {
        components: { SkeletonLoading, Empty },         
        data() {
            return {          
                onLoad: true,      
                loadTimeout: null,            
                demandRequest: [0,0,0,0,0,0,0,0,0,0,0,0],
                initialInventory:50,
                serviceLevel:[90],
                annualCarryingCost:15,
                avgLeadTime:'',
                leadTimeType:'',
                minimumLeadTime:'',
                maximumLeadTime:'',
                unitPrice:'',
                orderCost:'',
                standardDeviationLeadTime:'',
                loadingCalculate:false,
                calculations:[],
                modalDetail:false,
                availableInvetory:[],
                orderEntry:[],
                orderIssued:[],
                loadingRefresh:false,
                summary:{},
                disabledFixed:false,
                canChart:null,
                leadTimes:[0],
                metodeEoq:'', 
                loadingDownload:false,
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
            calculationData(){
                if(this.calculations && Object.keys(this.calculations).length > 0){
                    let tables = []
                    const foramtCalculateData = {};
                    for (const key in this.calculations) {
                        const newKey = key.replace(/\s+/g, '_').toLowerCase();
                        foramtCalculateData[newKey] = this.calculations[key];
                    }
                    tables.push(foramtCalculateData)

                    return tables;                   
                }else{
                    return []
                }
            },
            disableCalculate(){
                if(this.initialInventory === '' || this.unitPrice === '' || this.metodeROQ == ''){
                    return true;
                }else{
                    return false;
                }
            },
            disableAnnual(){
                if(this.metodeROQ != '' && this.metodeROQ == "false"){
                    this.annualCarryingCost = ''
                    return true;
                }else{
                    return false;
                }
            },
            disableOrderCost(){
                 if(this.metodeROQ != '' && this.metodeROQ == "false"){
                    this.orderCost = 0
                    return true;
                }else{
                    return false;
                }
            }
        },
        watch:{
            'calculationData': function(val){
                if(val.length > 0){
                    let findData = this.calculationData[0]
                    this.summary = findData;
                }else{
                    this.summary = {}
                }
            },
            'leadTimeType': function(val){
                if(val == 'Fixed'){
                    this.standardDeviationLeadTime = 0;
                    this.minimumLeadTime = 0;
                    this.maximumLeadTime = 0;
                    this.disabledFixed = true;
                }else{
                    this.standardDeviationLeadTime = '';
                    this.minimumLeadTime = '';
                    this.maximumLeadTime = '';
                    this.disabledFixed = false;
                }
            },
        },
        methods: {
            async calculate() {
                this.loadingCalculate = true;
                this.calculations = []
                if(this.serviceLevel.length == 0){
                    this.completeDataNotify('Service Level');
                    return;
                }
                
                this.demandRequest.forEach((element, index) => {
                    this.demandRequest[index] = element < 0 ? 0 : Number(element)
                })
                 this.serviceLevel.forEach((element, index) => {
                    this.serviceLevel[index] = Number(element)
                })
                
                this.leadTimes.forEach((element, index) => {
                    this.leadTimes[index] = element < 0 ? 0 : Number(element)
                })

                var unitPrice = Number(this.unitPrice.replaceAll('.','').replaceAll(',','.'))
                var orderCost = (this.orderCost == 0) ? 0 : Number(this.orderCost.replaceAll('.','').replaceAll(',','.'))
 
                let params = JSON.stringify(
                {
                    // "Lead Time Type":this.leadTimeType,
                    // "Average Lead Time":Number(this.avgLeadTime),
                    // "Standard Deviation Lead Time":Number(this.standardDeviationLeadTime),
                    // "Maximum Lead Time":Number(this.maximumLeadTime),
                    // "Minimum Lead Time":Number(this.minimumLeadTime),
                    // "Leadtime" : this.leadTimes,
                    // "Service Level":this.serviceLevel,
                    // "Initial Inventory":Number(this.initialInventory),
                    // "Annual Carrying Cost":Number(this.annualCarryingCost),
                    // "Standard Deviation Lead Time":Number(this.standardDeviationLeadTime),
                    // "Unit Price":unitPrice,
                    // "Biaya Pemesanan":orderCost,
                    // "Request Demand":this.demandRequest,
                    // "ROQ dengan Cost": this.metodeEoq

                    "demand_list": this.demandRequest,
                    "leadtime_list": this.leadTimes,
                    "unit_price": Number(unitPrice),
                    "percent_holding": Number(this.annualCarryingCost),
                    "service_level": parseFloat(this.serviceLevel),
                    "initial_inventory":Number(this.initialInventory),
                    "order_cost": orderCost,
                    "ROQ_with_cost": this.metodeEoq === 'true' ? true : false
                })

                var config = {
                    method: 'post',
                    url: 'https://stock-calc-be-v2.pupuk.in/manual-input',
                    headers: { 
                        'Content-Type': 'application/json'
                    },
                    data : params
                };

                await axios(config).then(response =>  this.calculations =  response.data)
                .catch(error => this.calculateError());
                this.loadingCalculate = false;
            },
            completeDataNotify(title) {
            	this.$notify({
					message: this.tt("please_complete_data", { title: title }),                  
					type: 'danger',
					verticalAlign: "bottom",
					horizontalAlign: "left"
				});
            },
            addServiceLevel(){
                if (this.serviceLevel.length == 0) {
                    this.serviceLevel.push('')
                } else if (this.serviceLevel.length > 0) {
                    if (this.serviceLevel[this.serviceLevel.length-1] != '') {
                        this.serviceLevel.push('')
                    } else {
                        this.completeDataNotify('Service Level')
                    }
                }
            },
            removeServiceLevel(key){
                this.confirmDialog(this.tt("confirm_delete")).then(result => {
			        if (result.value) {
			        	this.serviceLevel.splice(key, 1)
			        }
			    });
            },
            displayChart(key) {
                this.modalDetail = true;

                const canvasWrapper = document.getElementById('canvasWrapper');
				canvasWrapper.innerHTML = '<canvas id="myChart"></canvas>'

                const ctx = document.getElementById('myChart').getContext('2d');
				if (!ctx) {
                    this.$notify({
                            message: "Canvas element not found with ID 'myChart'.",
                            type: 'danger',
                            verticalAlign: "bottom",
                            horizontalAlign: "left"
                    });
                    return;
				}

                const DATA_COUNT = 13;
                const labels = [];
                for (let i = 1; i < DATA_COUNT; ++i) {
                    labels.push(i.toString());
                }
                const datapoints1   = this.calculationData[key].available_inventory   
                // const datapoints2   = [];
                const datapoints2   = Array(12).fill(Number(this.calculationData[key].safety_stock));
                // const datapoints3   = [];
                const datapoints3   = Array(12).fill(Number(this.calculationData[key].reorder_point));

                this.availableInvetory = this.calculationData[key].available_inventory;
                this.orderEntry = this.calculationData[key].order_entry
                this.orderIssued = this.calculationData[key].order_issued
                
                // for(var i = 0; i < 12 ; i++){
                //     datapoints2.push(Number(this.calculationData[key].Safety_Stock))
                // }

                // for(var i = 0; i < 12 ; i++){
                //     datapoints3.push(Number(this.calculationData[key].Reorder_Point))
                // }
                const data = {
                    labels: labels,
                    datasets: [
                        {
                            label: 'Availibility Inventory',
                            data: datapoints1,
                            borderColor: 'rgb(255, 0, 0)',
                            fill: false,
                            cubicInterpolationMode: 'monotone',
                            tension: 0.4
                        }, {
                            label: 'Safety Stock',
                            data: datapoints2,
                            borderColor: 'rgb(0, 255, 0)',
                            fill: false,
                            tension: 0.4,
                            pointRadius:0,
                            segment:{
                                borderDash:[6,6]
                            }
                        }, {
                            label: 'Reorder Point',
                            data: datapoints3,
                            borderColor: 'rgb(0, 0, 255)',
                            fill: false,
                            pointRadius:0,
                            segment:{
                                borderDash:[6,6]
                            }
                        }
                    ]
                };
                

                this.canChart = new Chart(ctx, {
                    type: 'line',
                    data: data,
                    options: {
                        responsive: true,
                        plugins: {
                            title: {
                                display: true,
                                text: 'Order Point Technique'
                            },
                            tooltip:{
                                callbacks:{
                                    title: function(context){
                                        return `Month (${context[0].label})`
                                    }
                                }
                            }
                        },
                        interaction: {
                            intersect: false,
                        },
                        scales: {
                            x: {
                                display: true,
                                title: {
                                    display: true,
                                    text: 'Month',
                                }
                            },
                            y: {
                                display: true,
                                title: {
                                    display: true,
                                    text: 'Unit'
                                },
                                suggestedMin: 0,
                            }
                        }
                    },
                });
            },
            async refreshData() {
                this.loadingRefresh = true;
                var config = {
                    method: 'post',
                    url: 'https://api-fm.pupuk-indonesia.com/refresh-data-source',
                    headers: { },
                };
                await axios(config).then(response => this.$notify({
                        message :response.data["Message"],
                        type: 'success',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                })).catch(() => {
                    this.$notify({
                        message :'Internal Server Error',
                        type: 'danger',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    })
                });
                this.loadingRefresh = false;
            },
            formatNumber(value) {
                let convert = value.toString();
                let splitValue  = convert.split('.');
                let withDot = splitValue[0].replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                if(!!splitValue[1]){
                    return 'Rp. ' + withDot + ',' + splitValue[1]
                }else{
                    return 'Rp. ' + withDot
                }
            },
            resetParameter(){
                // this.materialNumber = '' 
                this.serviceLevel= [90] 
                this.initialInventory = 50 
                this.annualCarryingCost = 15
                // this.avgLeadTime = ''
                // this.leadTimeType = ''
                // this.minimumLeadTime = ''
                // this.maximumLeadTime = ''
                this.unitPrice = ''
                this.orderCost = this.formatNumber(5000000,'orderCost')
                // this.standardDeviationLeadTime = ''
                this.demandRequest = [0,0,0,0,0,0,0,0,0,0,0,0]
                this.calculations = []
                this.leadTimes = []
            },
            formatPrice(val, variabel) {
                var price = val.target.value.replace(/[^,\d]/g, "").toString()
                price = price.replace(/[^,\d]/g, "").toString();
                var split = price.split(",");
                var lastPrice = split[0].length % 3;
                var formatRupiah = split[0].substr(0, lastPrice);
                var ribuan = split[0].substr(lastPrice).match(/\d{3}/gi);

                if (ribuan) {
                    var separator = lastPrice ? "." : "";
                    formatRupiah += separator + ribuan.join(".");
                }
                formatRupiah = split[1] != undefined ? formatRupiah + "," + split[1] : formatRupiah;
                this[variabel] = formatRupiah
            },
            calculateError(){
                this.$notify({
					message: 'Material tidak ditemukan data leadtime, disarankan menggunakan kalkulator manual input',                  
					type: 'danger',
					verticalAlign: "bottom",
					horizontalAlign: "left"
				});
            },
            addLeadTime(){
                if(this.leadTimes.length >= 12){
                    this.$notify({
                        message: 'Maximum Lead Time is 12',                  
                        type: 'danger',
                        verticalAlign: "bottom",
                        horizontalAlign: "left"
                    });
                    return;
                }
                this.leadTimes.push("")
            },
            deleteLeadTime(){
                this.leadTimes.splice(-1,1);
            },
            async downloadFile(){

                this.loadingDownload = true;
				try {
                    const data = this.calculationData.map(row => ({
                        service_level: row.service_level ? row.service_level : '-',
                        safety_stock: row.safety_stock ? row.safety_stock : '-',
                        rop: row.reorder_point ? row.reorder_point : '-',
                        min: row.minimum_stock ? row.minimum_stock : '-',
                        max: row.maximum_stock ? row.maximum_stock : '-',
                        eoq: row.roq ? row.roq : '-',
                        total_biaya: row.total_biaya ? row.total_biaya : '-',
                    }));

                    // Membuat worksheet dan workbook
                    const ws = XLSX.utils.json_to_sheet(data);
                    const wb = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(wb, ws, 'Results');

                    // Membuat file Excel
                    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

                    // Mendapatkan tanggal saat ini
                    const now = new Date();
                    const year = now.getFullYear();
                    const month = String(now.getMonth() + 1).padStart(2, '0');
                    const day = String(now.getDate()).padStart(2, '0');

                    // Menambahkan tanggal ke nama file
                    const fileName = `calculation_manual_input.xlsx`;

                    // Membuat file blob untuk diunduh
                    const fileUrl = window.URL.createObjectURL(new Blob([excelBuffer]));

                    // Membuat elemen <a> sementara untuk download file
                    const link = document.createElement('a');
                    link.href = fileUrl;
                    link.setAttribute('download', fileName);

                    // Menambahkan link ke body dan memicu klik untuk mendownload file
                    document.body.appendChild(link);
                    link.click();

                    // Membersihkan elemen setelah download selesai
                    link.remove();
                    window.URL.revokeObjectURL(fileUrl);
				} catch (error) {
						// console.error('Gagal membuat dan mendownload file Excel:', error);
                    this.$notify({
                            message: 'Gagal membuat dan mendownload file Excel: ' + error,
                            type: 'danger',
                            verticalAlign: "bottom",
                            horizontalAlign: "left"
                    });
				} finally {
                    this.loadingDownload = false;
				}
                
            }
        }   
    };
</script>

<style scoped>
.el-tooltip__popper {
  max-width: 320px !important;
  word-break: break-word;
}

.customTableKecil tbody tr th, td {
  padding: 6px !important;
  text-align: center;
}
</style>